<footer class="footer-area footer-bg2">
    <div class="footer-middle pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <a routerLink="/" class="logo"
                            ><img
                                src="assets/img/logo_black_small.png"
                                alt="Logo"
                        /></a>

                        <ul class="footer-contact-list">
                            <li>Россия, г.Новочеркасск,</li>
                            <!-- <li>пер. Цимлянский 1В</li> -->
                            <li>
                                <span>Телефон :</span>
                                <a href="tel:+79508684766"> +7 950 868 47 66</a>
                            </li>
                            <li>
                                <span>Email :</span>
                                <a href="mailto:sale@ronroc.ru">sale&#64;ronroc.ru</a>
                            </li>
                        </ul>
                        <ul class="social-link">
                            <li>
                                <a
                                    href="https://vk.com/public211447112"
                                    target="_blank"
                                    ><i class="bx bxl-vk"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://t.me/RonrocSale"
                                    target="_blank"
                                    ><i class="bx bxl-telegram"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://wa.me/+79508684766"
                                    target="_blank"
                                    ><i class="bx bxl-whatsapp"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget pl-5">
                        <h3>Ссылки</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/about">О нас</a></li>
                            <li><a routerLink="/contact">Контакты</a></li>
                            <li><a routerLink="/ателье-по-пошиву-одежды">Ателье по ремонту и пошиву одежды</a></li>
                            <li><a routerLink="/швейная-фабрика">Швейная фабрика</a></li>
                            <li><a routerLink="/магазин-женской-одежды">Магазин женской одежды</a></li>
                            <li><a routerLink="/женская-одежда-оптом">Одежда оптом</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget pl-5">
                        <h3>Сми о нас</h3>

                        <ul class="footer-list">
                            <li><a href="https://mbrostov.ru/news/predprinimatelnica-iz-novocerkasska-s-gospodderzkoi-otkryla-sveinoe-art-prostranstvo">Мой бизнес Ростовская область</a></li>
                            <li><a href="https://1rostov.tv/tv-stories/shvejnoe-art-prostranstvo-s-pomoshhyu-czentra-moj-biznes-organizovali-v-novocherkasske/">Первый Ростовский</a></li>
                            <li><a href="https://rutube.ru/video/2076d4850a5709b5a0df5c70df4c53b3/?t=0">Первый Ростовский Rutube</a></li>
                            <li><a href="https://don24.tv/special/iz-hobbi-v-biznes.html">Дон 24</a></li>
                            <li><a href="https://vkvideo.ru/video-99173315_456264539?ref_domain=don24.tv">Дон 24 VKVideo</a></li>
                            <li><a href="https://t.me/mb_novocherkassk/1472">Центр «Мой бизнес» | Новочеркасск</a></li>
                            <li><a href="https://t.me/novochgrad/15869">Администрация Новочеркасска</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
