import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/app.service";
import { cardNew } from "src/assets/class/cardNew";

@Component({
    selector: "app-city-area",
    templateUrl: "./city-area.component.html",
    styleUrls: ["./city-area.component.scss"],
    standalone: false
})
export class CityAreaComponent implements OnInit {
    constructor(public appService: AppService) {
/*         if (this.appService.cardNew === undefined) {
            this.appService.http
                .get(this.appService.host + "/items")
                .subscribe((response: cardNew[]) => {
                    this.appService.cardNew = response;
                    this.appService.cardHit = this.appService.cardNew;
                });
        } */
    }
    ngOnInit(): void {
        this.getCardHit();
    }
    getCardHit() {
/*         if (this.appService.cardNew !== undefined) {
            this.appService.cardHit = this.appService.cardNew.filter((item) => {
                return (
                    item.categoryMain === this.appService.categoryThis &&
                    item.hit === 1
                );
            });
        } */
    }
}
