import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/app.service";
import { cardNew } from "src/assets/class/cardNew";
import { Observable } from "rxjs";

@Component({
    selector: "app-place-list",
    templateUrl: "./place-list.component.html",
    styleUrls: ["./place-list.component.scss"],
    standalone: false
})
export class PlaceListComponent implements OnInit {
    cardNew$: Observable<cardNew[]>;

    constructor(public appService: AppService) {
        this.cardNew$ = appService.cardNew;
    }
    ngOnInit(): void {
        this.appService.getDataNewcard();
    }
}
