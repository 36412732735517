<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Наши Контакты</h3>
            <ul>
                <li><a href="index.html">RonRoc</a></li>
                <li><i class="bx bx-chevron-right"></i></li>
                <li>Контакты</li>
            </ul>
        </div>
    </div>
</div>

<div class="contact-area">
    <div class="container">
        <div class="contact-max">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-position"></i>
                        <h3>Россия, г.Новочеркасск,</h3>
                        <!-- <h3>пер. Цимлянский 1В</h3> -->
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-email"></i>
                        <h3>
                            <a href="mailto:sale@ronroc.ru">Email:sale&#64;ronroc.ru</a>
                        </h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="contact-card">
                        <i class="flaticon-telephone"></i>
                        <h3><a href="tel:+7(950)8684766">+7(950)8684766</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d802.5244683112459!2d40.049527773951525!3d47.424427279376005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40e24965d6fe38a5%3A0xd96cdbd2b3646314!2z0KbQuNC80LvRj9C90YHQutC40Lkg0L_QtdGALiwgMdCyLCDQndC-0LLQvtGH0LXRgNC60LDRgdGB0LosINCg0L7RgdGC0L7QstGB0LrQsNGPINC-0LHQuy4sIDM0NjQyMQ!5e0!3m2!1sru!2sru!4v1693307556241!5m2!1sru!2sru"
        ></iframe>

        <div class="contact-wrap">
            <div class="contact-form">
                <span>Отправить Сообщение</span>
                <h2>Связь С Нами</h2>

                <form id="contactForm" (ngSubmit)="submitForm()" target="_blank">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class="bx bx-user"></i>
                                <input
                                    type="text"
                                    [(ngModel)]="formData.username"
                                    name="name"
                                    id="name"
                                    class="form-control"
                                    required
                                    placeholder="Имя*"
                                />
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class="bx bx-user"></i>
                                <input
                                    type="email"
                                    [(ngModel)]="formData.email"
                                    name="email"
                                    id="email"
                                    class="form-control"
                                    required
                                    placeholder="E-mail*"
                                />
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class="bx bx-phone"></i>
                                <input
                                    type="text"
                                    [(ngModel)]="formData.phoneNumber"
                                    name="phone_number"
                                    id="phone_number"
                                    required
                                    class="form-control"
                                    placeholder="Телефон"
                                />
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class="bx bx-file"></i>
                                <input
                                    type="text"
                                    [(ngModel)]="formData.text"
                                    name="msg_subject"
                                    id="msg_subject"
                                    class="form-control"
                                    required
                                    placeholder="Тема сообщения"
                                />
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <i class="bx bx-envelope"></i>
                                <textarea
                                    name="message"
                                    [(ngModel)]="formData.message"
                                    class="form-control"
                                    id="message"
                                    cols="30"
                                    rows="8"
                                    required
                                    placeholder="Тест сообщения"
                                ></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button class="default-btn border-radius">
                                Отправить<i class="bx bx-plus"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>
