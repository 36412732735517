import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-listing',
    templateUrl: './listing.component.html',
    styleUrls: ['./listing.component.scss'],
    standalone: false
})
export class ListingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
