import { Component, OnInit } from "@angular/core";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";


@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
    standalone: false
})
export class ContactComponent implements OnInit {
    formData = {
        username: "",
        email: "",
        text: "",
        message: "",
        phoneNumber: "",
    };

    constructor(private http: HttpClient) {}

    ngOnInit(): void {}

    submitForm() {
        this.postData(this.formData);
       // window.open("/", "_self");
    }

    postData(formData) {
        const body = formData;
        //return this.http.post("http://localhost:3000/contact", body).subscribe();
        return this.http.post("https://test.ronroc.ru:81/data/contact", body ).subscribe();
    }
}
