import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
@Component({
    selector: 'app-factory',
    templateUrl: './factory.component.html',
    styleUrls: ['./factory.component.scss'],
    standalone: false
})
export class FactoryComponent {
  constructor(private meta: Meta) {
    this.meta.addTag({ name: 'description', content: 'Наша швейная фабрика предлагает профессиональное производство одежды на заказ. Высококачественные материалы, современное оборудование и опытные мастера – всё для создания идеальных изделий. Закажите пошив оптом или индивидуально и получите продукцию, соответствующую высоким стандартам качества!' });
  }

}
