import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
@Component({
    selector: 'app-studio',
    templateUrl: './studio.component.html',
    styleUrls: ['./studio.component.scss'],
    standalone: false
})
export class StudioComponent {
  constructor(private meta: Meta) {
    this.meta.addTag({ name: 'description', content: 'Наше ателье по ремонту и пошиву одежды предлагает профессиональные услуги по ремонту и созданию уникальных нарядов. Мы поможем обновить ваш гардероб: от мелкого ремонта до индивидуального пошива. Доверьтесь нашим мастерам и получите идеальный результат!' });
  }
}
