import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-navbar-style-one',
    templateUrl: './navbar-style-one.component.html',
    styleUrls: ['./navbar-style-one.component.scss'],
    standalone: false
})
export class NavbarStyleOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
