import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/app.service";
import { Reviews } from "src/assets/class/reviews";

@Component({
    selector: "app-reviews-area",
    templateUrl: "./reviews-area.component.html",
    styleUrls: ["./reviews-area.component.scss"],
    standalone: false
})
export class ReviewsAreaComponent implements OnInit {
    constructor(public appService: AppService) {
        if (this.appService.reviews === undefined) {
            this.appService.http
                .get(this.appService.host + "/reviews")
                .subscribe((response: Reviews[]) => {
                    this.appService.reviews = response;
                });
        }
    }

    ngOnInit(): void {}
}
