import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { AppService } from "src/app/app.service";

@Component({
    selector: "app-category",
    templateUrl: "./category.component.html",
    styleUrls: ["./category.component.scss"],
    standalone: false
})
export class CategoryComponent implements OnInit {
    id: Number | undefined;
    cat: Number | undefined;
    private subscription: Subscription;
    categorySubThis;

    constructor(
        private activateRoute: ActivatedRoute,
        public appService: AppService
    ) {
        if (this.appService.categoryMain === undefined) {
            this.appService.http
                .get(this.appService.host + "/categoryMain")
                .subscribe((response: []) => {
                    this.appService.categoryMain = response;
                    if (this.appService.categoryMain)
                        this.appService.getCategoryMainThis();
                });
        }
        this.subscription = activateRoute.params.subscribe((params) => {
            this.id = params["id"];
            this.cat = params["cat"];

            this.appService.categoryThis = this.cat;

            if (this.appService.categorySub) {
                this.appService.getCategorySub();
                this.appService.getCategorySubThis(this.id);
            }
            if (this.appService.categoryMain)
                this.appService.getCategoryMainThis();
        });
    }

    ngOnInit(): void {}
}
