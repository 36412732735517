<app-navbar-style-two></app-navbar-style-two>

<div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="container-max">
                    <div class="banner-content ronroc-logo-home">
                        <!--                         <h2>1</h2>
                        <h1 class="ronroc">RONROC</h1> -->
                        <div class="text-main">
                            <h2 calss="white-text ronroc-text-home">
                                <b>
                                    Швейное арт-пространство, в котором
                                    воплощаются мечты
                                </b>
                            </h2>
                        </div>
                        <div class="form-list">
                            <ul>
                                <li [class.active]="0">
                                    <i class="flaticon-curve-arrow"></i>Ателье
                                </li>
                                <li [class.active]="0">
                                    <i class="flaticon-curve-arrow"></i
                                    >Дизайн-RONROC
                                </li>
                                <li [class.active]="0">
                                    <i class="flaticon-curve-arrow"></i>B2b
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-place-list></app-place-list>

<app-services></app-services>

<app-advantages></app-advantages>

<app-footer-style-two></app-footer-style-two>
