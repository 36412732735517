import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/app.service";

@Component({
    selector: "app-category-area",
    templateUrl: "./category-area.component.html",
    styleUrls: ["./category-area.component.scss"],
    standalone: false
})
export class CategoryAreaComponent implements OnInit {
    constructor(public appService: AppService) {
        if (this.appService.categorySub === undefined) {
            this.appService.http
                .get(this.appService.host + "/categorySub")
                .subscribe((response: []) => {
                    this.appService.categorySub = response;
                    this.appService.getCategorySub();
                });
        }
    }
    ngOnInit(): void {
        if (this.appService.categorySub) {
            this.appService.getCategorySub();
        }
    }
}
