import { Component, OnInit } from '@angular/core';
import { AppService } from "src/app/app.service";
import { cardNew } from "src/assets/class/cardNew";
import { Observable } from "rxjs";
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.scss'],
    standalone: false
})
export class ShopComponent implements OnInit {
  cardNew$: Observable<cardNew[]>;

  constructor(public appService: AppService, private meta: Meta) {
      this.cardNew$ = appService.cardNew;
      this.meta.addTag({ name: 'description', content: 'Предлагаем женскую одежду оптом по выгодным ценам. Широкий ассортимент модных коллекций, высокое качество и быстрая доставка. Обновите ассортимент своего магазина стильными новинками и предложите клиентам лучшее!' });
  }
  ngOnInit(): void {
      this.appService.getDataNewcard();
  }
}
