import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/app.service";

@Component({
    selector: "app-navbar-style-two",
    templateUrl: "./navbar-style-two.component.html",
    styleUrls: ["./navbar-style-two.component.scss"],
    standalone: false
})
export class NavbarStyleTwoComponent implements OnInit {
    constructor(public appService: AppService) {}

    ngOnInit(): void {
        if (this.appService.categoryMain === undefined) {
            this.appService.http
                .get(this.appService.host + "/categoryMain")
                .subscribe((response: []) => {
                    this.appService.categoryMain = response;
                });
        }
        if (this.appService.categorySub === undefined) {
            this.appService.http
                .get(this.appService.host + "/categorySub")
                .subscribe((response: []) => {
                    this.appService.categorySub = response;
                });
        }
    }
}
