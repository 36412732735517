import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-shop-about',
    templateUrl: './shop-about.component.html',
    styleUrls: ['./shop-about.component.scss'],
    standalone: false
})
export class ShopAboutComponent {
  constructor(private meta: Meta) {
    this.meta.addTag({ name: 'description', content: 'Наш магазин женской одежды собственного производства предлагает уникальные коллекции, созданные с любовью и вниманием к деталям. Мы контролируем каждый этап создания одежды, гарантируя высокое качество и оригинальный дизайн. Обновите гардероб стильными нарядами, разработанными специально для вас!' });
}
}
